@font-face {
  font-family: 'Ukraine';
  src:  url('./fonts/e-Ukraine-Regular.otf?v=1') format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Ukraine';
  src:  url('./fonts/e-Ukraine-Bold.otf?v=1') format("opentype");
  font-weight: bold;
  font-style: normal;
}

/* @font-face {
  font-family: 'Roboto';
  src:  url('./fonts/Roboto/Roboto-Regular.ttf') format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src:  url('./fonts/Roboto/Roboto-Bold.ttf') format("opentype");
  font-weight: bold;
  font-style: normal;
} */


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Ukraine";
  /*background: #071C3F; 061C47*/
  background: rgb(7,38,99);
  background: linear-gradient(180deg, rgba(7,38,99,1) 0%, rgba(7,33,85,1) 100%);
  /* font-family: "Arsenal"; */
  /* font-family: 'Roboto'; */
}


