

.filler {
  font-size: 16px;
  line-height: 1.45em;
  padding-left: 30vw;
  padding-right: 30vw
}
#scrolly-overlay .scrolly {
  background-color: none;
  margin: 3rem auto;
  max-width: 80rem;
  padding: 1rem
}
#scrolly-overlay .scrolly article {
  margin: 0 auto;
  max-width: 40rem;
  padding: 0;
  position: relative
}
#scrolly-overlay .scrolly article .step {
  margin-bottom: 1rem;
  min-height: 80vh
}
#scrolly-overlay .scrolly article .step:last-of-type {
  margin-bottom: 0
}
.step p {
  box-shadow: 1px 1px 5px 5px #ddd
}
#scrolly-overlay .scrolly article .step.is-active p {
  opacity: .6
}
#scrolly-overlay .scrolly article .step p {
  background-color: #fff;
  border: 1px solid #657786;
  color: #000;
  font-weight: 400;
  margin: 0;
  opacity: .9;
  padding: 1rem;
  text-align: center;
  transition: background-color .25s ease-in-out
}
#scrolly-overlay .scrolly figure.sticky {
  background: none;
  height: 65vh;
  left: 0;
  margin: 0;
  pointer-events: all !important;
  position: -webkit-sticky;
  position: sticky;
  top: 10vh;
  width: 100%
}
.col__wide, .col__wide__inner, .layout_second, .layout_width {
  overflow: visible !important
}
.col__wide, .layout_second {
  float: none
}

circle:hover {
  stroke-width: 3;
  stroke:#fff;
}
@media (max-width:667px) {
  .filler {
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 0
  }
  .standWU{
    display: "inline-block";
    position: relative;
    top: 30px;
  }
}



/* --- Animation --- */


.dot {
  width: var(--dot-size);
  height: var(--dot-size);
  background-color: red;
  border:5px solid #fff;
  /* padding: '20px'; */
  border-radius: 50%;
}

/* Define animation keyframes */
@keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

/* Minimal example */
.dot--basic {
  animation: blink 2s infinite;
}

/* Run animation once */
.dot--once {
  animation: blink 2s 1;
  /* animation-iteration-count: 1; */
}

/* Wait 4s before running the animation */
.dot--delayed {
  animation: blink 2s infinite 4s;
  /* animation-delay: 4s; */
}

/* Use frames with "from" and "to" */
@keyframes choppyBlink {
  from { opacity: 0; }
  to { opacity: 1; }
}
.dot--choppy {
  animation: choppyBlink 2s infinite;
  /* animation-name: choppyBlink; */
}

/* Animate multiple properties (transform and color) */
@keyframes pulse {
  0%, 100% {
    transform: scale(0) translateY(-75%);
    background-color: blue;
  }
  33% {
    background-color: orange;
  }
  50% {
    transform: scale(1.125) translateY(0);
    background-color: red;
  }
}
.dot--pulse {
  animation: pulse 8s infinite;
}

/* Disable animation if user prefers reduced motion */
@media (prefers-reduced-motion: reduce) {
  .dot {
    animation: none;
  }
}


/* --- Sample Usage --- */

.sample-item {
  --dot-size: 0.5rem;
  --offline-color: #999;
  --offline-text-color: #666;

  display: inline-flex;
  align-items: center;
  /* border: 2px solid green; */
  border-radius: 14px;
  padding: .3rem 1rem;
}

.sample-item .dot {
  margin-right: .3rem;
  margin-bottom: .3rem;
}

.sample-item--offline {
  border-color: var(--offline-color);
  color: var(--offline-text-color);
}

.sample-item--offline .dot {
  animation: none;
  background-color: var(--offline-color);
}






.slidecontainer {
  width: 100%; /* Width of the outside container */
  padding: 0 10px;
}

/* The slider itself */
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 5px; /* Specified height */
  background: #0A2B6C; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.9; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}



/* Mouse-over effects */
.slider:not(.sliderDisable):hover {
  opacity: 1; /* Fully shown on mouse-over */
}
.sliderDisable{
  opacity: 0.2;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: #FFCE06; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 10px;
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: #FFCE06
  ; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 10px;
}

input{
  color: #fff;
}

textarea:focus, input:focus {
  color: #fff;
}

.textZoom{
  font-size: 20px;
  color: #ffffff;
}
